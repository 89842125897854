.product-image-slider .swiper-button-prev::after, .product-image-slider .swiper-button-next::after {
  padding: 0.75rem;
  color: var(--color-primary);
  background-color: var(--color-secondary);
  font-size: 1rem;
  opacity: 0.6;
}

.product-image-slider .swiper-button-prev {
  left: 0;
}

.product-image-slider .swiper-button-next {
  right: 0;
}

.product-image-thumb-list .swiper-slide {
  cursor: pointer;
  border: 1px solid transparent;
  opacity: 0.4;
}

.product-image-thumb-list .swiper-slide:hover {
  opacity: 0.8;
}

.product-image-thumb-list .swiper-slide-thumb-active, .product-image-thumb-list .swiper-slide-thumb-active:hover {
  border-color: var(--color-secondary-darker);
  opacity: 1;
}
