/* ************************* FONTS: start ************************* */
@import url('https://cdn.pm-international.com/assets-shared-frontend/fonts/uni-neue.css');
@import url('https://cdn.pm-international.com/assets-shared-frontend/fonts/clan-pro.css');
@import url('https://cdn.pm-international.com/assets-shared-frontend/fonts/helvetica-neue-lt-pro.css');
/* ************************* FONTS: end ************************* */


/* ************************* TOP: start ************************* */
html, body, #root {
  width: 100%;
}

/* hide scrollbar for all elements in Chrome, Safari, and Opera */
*::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}

/* hide scrollbar for all elements in Firefox */
* {
  scrollbar-width: none;
}
/* ************************* TOP: end ************************* */


/* ************************* VARIABLES: start ************************* */
:root {
  /* notification colors (based on Webshop) */
  --rgb-success: 15 154 101;
  --color-success: #0f9a65;
  --rgb-info: 29 69 130;
  --color-info: #1d4582;
  --rgb-warn: 191 98 3;
  --color-warn: #bf6203;
  --rgb-error: 205 0 57;
  --color-error: #cd0039;

  /* PM-International brand colors */
  --rgb-pm-blue-lightest: 239 246 255;
  --color-pm-blue-lightest: #eff6ff;
  --rgb-pm-light-blue: 202 227 246; /* official pm light-blue */
  --color-pm-light-blue: #cae3f6; /* official pm light-blue */
  --rgb-pm-light-blue-darker: 115 170 211; /* darker hue of pm light-blue */
  --color-pm-light-blue-darker: hsl(206, 52%, 64%); /* darker hue of pm light-blue */
  --rgb-pm-light-blue-darkest: 84 133 171; /* darkest hue of pm light-blue */
  --color-pm-light-blue-darkest: rgb(84, 133, 171); /* darkest hue of pm light-blue */
  --rgb-pm-blue: 0 75 141; /* official pm blue */
  --color-pm-blue: #004b8d; /* official pm blue */
  --rgb-pm-blue-darker: 0 67 126;
  --color-pm-blue-darker: #00437e;

  --rgb-fitline-red-lightest: 245 204 215; /* fitline red 20% */
  --color-fitline-red-lightest: #f5ccd7; /* fitline red 20% */
  --rgb-fitline-red: 205 0 57; /* fitline red */
  --color-fitline-red: #cd0039; /* fitline red */
  --rgb-fitline-red-darker: 184 0 51;
  --color-fitline-red-darker: #b80033;
  --rgb-pm-light-peach: 255 250 243; /* peach support */
  --color-pm-light-peach: #fffaf3; /* peach support */
  --rgb-pm-peach: 255 243 224; /* peach */
  --color-pm-peach: #fff3e0; /* peach */
  --rgb-pm-darker-peach: 246 215 164; /* dark peach */
  --color-pm-darker-peach: #f6d7a4; /* dark peach */
  --rgb-pm-darkest-peach: 215 160 71;
  --color-pm-darkest-peach: rgb(215, 160, 71);
  --rgb-silver: 167 169 172;
  --color-silver: #a7a9ac;

  --rgb-main: 9 9 9;
  --color-main: #090909;
  --rgb-main-lighter: 128 128 128;
  --color-main-lighter: #808080;
  --rgb-main-lightest: 206 206 206;
  --color-main-lightest: #cecece;

  /* other variables */
  --border-radius-button: 5rem;
}

/* ************************* VARIABLES: end ************************* */


/* ************************* FitLine Theme: start ************************* */
[data-pmiweb-theme='fitline'] {
  --font-family-main: 'helvetica-neue';
  --font-family-heading: 'clan-pro';

  --rgb-bg-color: var(--rgb-secondary-lighter);
  --color-bg-color: var(--color-secondary-lighter);

  --rgb-primary-lightest: var(--rgb-fitline-red-lightest); /* fitline red 20% */
  --color-primary-lightest: var(--color-fitline-red-lightest); /* fitline red 20% */
  --rgb-primary: var(--rgb-fitline-red); /* fitline red */
  --color-primary: var(--color-fitline-red); /* fitline red */
  --rgb-primary-darker: var(--rgb-fitline-red-darker); /* used only on hover*/
  --color-primary-darker: var(--color-fitline-red-darker); /* used only on hover*/
  /* --rgb-primary-interaction: var(--rgb-fitline-red-darker); */
  /* --color-primary-interaction: var(--color-fitline-red-darker); */

  --rgb-secondary-lightest: var(--rgb-pm-peach) / 0.2;
  --color-secondary-lightest: rgb(var(--rgb-secondary-lightest));
  --rgb-secondary-lighter: var(--rgb-pm-light-peach);
  --color-secondary-lighter: var(--color-pm-light-peach);
  --rgb-secondary: var(--rgb-pm-peach);
  --color-secondary: var(--color-pm-peach);
  --rgb-secondary-darker: var(--rgb-pm-darker-peach);
  --color-secondary-darker: var(--color-pm-darker-peach);
  --rgb-secondary-darkest: var(--rgb-pm-darkest-peach);
  --color-secondary-darkest: var(--color-pm-darkest-peach);

  --rgb-primary-transparent-background: 229 241 251;
  --color-primary-transparent-background: rgb(229 241 251);
  --alpha-primary-transparent-background: 0.8;

  --rgb-body-bg: var(--rgb-secondary-lighter);
  --color-body-bg: var(--color-secondary-lighter);
}
/* ************************* FitLine Theme: end ************************* */


/* ************************* PMI Theme: start ************************* */
[data-pmiweb-theme='pm'] {
  --font-family-main: 'uni-neue', sans-serif;
  --font-family-heading: 'uni-neue', sans-serif;

  /* the PM-blue theme uses gray instead of secondary-lighter as page bg color*/
  --rgb-bg-color: 245 245 245;
  --color-bg-color: rgb(245 245 245);

  --rgb-primary-lightest: var(--rgb-pm-light-blue); /* official pm light-blue */
  --color-primary-lightest: var(--color-pm-light-blue); /* official pm light-blue */
  --rgb-primary: var(--rgb-pm-blue); /* pm blue */
  --color-primary: var(--color-pm-blue); /* pm blue */
  --rgb-primary-darker: var(--rgb-pm-blue-darker); /* used only on hover*/
  --color-primary-darker: var(--color-pm-blue-darker); /* used only on hover*/
  /* --rgb-primary-interaction: var(--rgb-pm-blue-darker); */
  /* --color-primary-interaction: var(--color-pm-blue-darker); */

  --rgb-secondary-lightest: var(--rgb-pm-light-blue) / 0.2;
  --color-secondary-lightest: rgb(var(--rgb-secondary-lightest));
  --rgb-secondary-lighter: var(--rgb-pm-light-blue) / 0.8;
  --color-secondary-lighter: var(--rgb-secondary-lighter);
  --rgb-secondary: var(--rgb-pm-light-blue);
  --color-secondary: var(--color-pm-light-blue);
  --rgb-secondary-darker: var(--rgb-pm-light-blue-darker);
  --color-secondary-darker: var(--color-pm-light-blue-darker);
  --rgb-secondary-darkest: var(--rgb-pm-light-blue-darkest);
  --color-secondary-darkest: var(--color-pm-light-blue-darkest);

  --rgb-primary-transparent-background: 229 241 251;
  --color-primary-transparent-background: rgb(229 241 251);
  --alpha-primary-transparent-background: 0.8;

  --rgb-body-bg: var(--rgb-secondary-lighter);
  --color-body-bg: var(--color-secondary-lighter);
}
/* ************************* PMI Theme: end ************************* */


/* ************************* CORE: start ************************* */
/* Drawer */
#drawer {
  position: relative;
  z-index: 80000;
}

/* Toast/Snackbar container */
.Toastify {
  position: relative;
  z-index: 90001;
}

/* Modal */
#modal {
  position: relative;
  z-index: 90000;
}

/* ************************* CORE: start ************************* */

/* ************************* TAILWIND: start ************************* */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
  }

  html,
  body {
    @apply font-main font-normal text-gray-700;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-heading font-bold;
  }
}

@layer components {
  *:focus {
    outline: none;
  }

  *:focus-visible {
    outline: none;
    @apply ring;
  }

  .link {
    @apply text-primary hover:text-primary-darker hover:underline;
  }

  /* https://www.geeksforgeeks.org/how-to-detect-touch-screen-device-using-css/ */
  @media (pointer: coarse) {
    .touch-link {
      @apply underline;
    }
  }
}

/* ************************* TAILWIND: end ************************* */


/* ************************* UTILITIES: start ************************* */
.scroll-block {
  touch-action: none;
  -webkit-overflow-scrolling: none;
  overflow: hidden;
  overscroll-behavior: none;
}

.mask-bottom {
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1.0) 0%, rgba(0, 0, 0, 1.0) 50%, transparent 100%);
}

/* ************************* UTILITIES: end ************************* */


/* ************************* ANIMATIONS: start ************************* */
@keyframes rotate {
  0% {
    transform: rotate(-180deg);
  }

  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

/* ************************* ANIMATIONS: end ************************* */
