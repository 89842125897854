.spinner-pm {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 64px;
  height: 64px;
  border-radius: 64px;
}

.spinner-pm:before {
  position: absolute;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  content: '';
  clip: rect(0, 64px, 32px, 0);
  box-shadow: inset -5px 0 0 5px rgb(var(--rgb-secondary-darker) / 1);
  color: inherit;
  -webkit-animation: rotate 1s infinite linear;
  animation: rotate 1s infinite linear;
}

.spinner-content {
  position: absolute;
  inset: 0;
  display: grid;
  place-items: center;
}
